@font-face {
  font-family: 'Genericons';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/genericons-regular-webfont.eot'); /* IE9 Compat Modes */
  src: url('../fonts/genericons-regular-webfont.eot') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/genericons-regular-webfont.woff') format('woff'), /* Modern Browsers */
       url('../fonts/genericons-regular-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/genericons-regular-webfont.svg') format('svg'); /* Legacy iOS */
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen-Sans","Ubuntu","Cantarell","Helvetica Neue",sans-serif;
}

.App > .content {
  box-sizing: content-box;
  max-width: 700px;
  margin: 0 auto;
  padding: 1.5em 1.75em 3.25em;
}

@media (max-width: 576px) {
  .App > .content {
    box-sizing: content-box;
    max-width: 700px;
    margin: 0 auto;
    padding: 0em 0.5em 3.25em;
  }
}
